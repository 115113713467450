import axiosInstance from "app/axiosInstance";

class AccountsService {
  static getAllAccounts = async () => {
    return await axiosInstance.get("/accounts");
  };

  static getSpecificAccount = async (accountId: string) => {
    return await axiosInstance.get(`/accounts/${accountId}`);
  };

  static createAccount = async (accountId: string, data: any) => {
    return await axiosInstance.post(`/accounts/${accountId}`, data);
  };

  static updateAccount = async (accountId: string, data: any) => {
    return await axiosInstance.patch(`/accounts/${accountId}`, data);
  };

  static deactivateAccount = async (accountId: string) => {
    return await axiosInstance.delete(`/accounts/${accountId}`);
  };

  static reactivateAccount = async (accountId: string) => {
    return await axiosInstance.patch(`/accounts/${accountId}/activate`);
  };

  static getAccountsIndustries = async () => {
    return await axiosInstance.get("/accounts/industries");
  };

  static getAccountsTypes = async () => {
    return await axiosInstance.get("/accounts/types");
  };

  //Account Objects
  static getAllAccountObjects = async (accountId: string) => {
    return await axiosInstance.get(`/accounts/${accountId}/objects`);
  };

  //Account Groups
  static getAllAccountGroups = async (accountId: string) => {
    return await axiosInstance.get(`/accounts/${accountId}/groups`);
  };

  static createAccountGroups = async (accountId: string, data: any) => {
    return await axiosInstance.post(`/accounts/${accountId}/groups`, data);
  };

  static updateAccountGroups = async (
    accountId: string,
    groupId: string,
    data: any
  ) => {
    return await axiosInstance.patch(
      `/accounts/${accountId}/groups/${groupId}`,
      data
    );
  };

  static getSpecificAccountGroup = async (
    accountId: string,
    groupId: string
  ) => {
    return await axiosInstance.get(`/accounts/${accountId}/groups/${groupId}`);
  };

  static removeGroupFromAccount = async (
    accountId: string,
    groupId: string
  ) => {
    return await axiosInstance.delete(
      `/accounts/${accountId}/groups/${groupId}`
    );
  };

  //Account Users
  static getSpecificAccountUser = async (accountId: string, userId: string) => {
    return await axiosInstance.get(`/accounts/${accountId}/users/${userId}`);
  };

  static getUsersBasicInfo = async (accountId: string) => {
    return await axiosInstance.get(`/accounts/${accountId}/users`);
  };

  // Account Point of Interests
  static getAccountPointsOfInterests = async (accountId: string) => {
    return await axiosInstance.get(`/accounts/${accountId}/pointsofinterest`);
  };

  // Account Features
  static getAccountFeatures = async (accountId: string) => {
    return await axiosInstance.get(`/accounts/${accountId}/features`);
  };

  // Initial Account
  static getInitialAccount = async () => {
    return await axiosInstance.post(`/accounts/users/initial`);
  };

  static searchMinimalAccounts = async (data: any) => {
    return await axiosInstance.post(`/accounts/minimalsearch`, data);
  };
}

export default AccountsService;
