import React, { Fragment, useMemo, memo, useState } from "react";
import {
  MarkerClustererF,
  OverlayViewF,
  OverlayView,
} from "@react-google-maps/api";
import { MarkerBlip, MarkerLabel } from "../../Map/Map.styled";
import ObjectMarker from "../ObjectMarker";
import { ClusterHeading, ClusterListing } from "./MarkerClusterer.styled";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton, Typography } from "@mui/material";
import { setActiveObjectId } from "app/features";
import { useAppDispatch } from "hooks";
import { NavigateNext, NavigateBefore } from "@mui/icons-material";
import ObjectList from "./ObjectList";

const options = {
  imagePath:
    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  zoomOnClick: false,
};

const getPixelPositionOffset = () => ({
  x: 20,
  y: -33,
});

interface CustomMarkerClustererProps {
  map: google.maps.Map | undefined;
  markers: Array<any>;
  selectedMarkerLoading: boolean;
  showClusters: boolean;
  selectedCluster: any;
  setSelectedCluster: (clusterData: any) => void;
  showObjectNames: boolean;
}

const MarkerClusterer: React.FC<CustomMarkerClustererProps> = ({
  map,
  markers,
  selectedMarkerLoading,
  showClusters,
  setSelectedCluster,
  selectedCluster,
  showObjectNames,
}) => {
  const dispatch = useAppDispatch();

  const [clusterObjects, setClusterObjects] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedMarker, setSelectedMarker] = useState<string>("");
  const objectsPerPage = 7;

  const visibleMarkers = useMemo(() => {
    // @ts-ignore
    if (!map || map.zoom <= 13) return markers;
    const mapBounds = map.getBounds();
    return markers.filter((marker) =>
      mapBounds?.contains(new window.google.maps.LatLng(marker.lat, marker.lng))
    );
  }, [map, markers]);

  const handleClusterClick = (cluster: any) => {
    dispatch(setActiveObjectId(null));
    const clickedMarkers = cluster.getMarkers();
    const _clusterObjects: any = [];
    clickedMarkers.map((item: any) => {
      const foundObject = visibleMarkers.find(
        (marker) =>
          marker.lat === item.position.lat() &&
          marker.lng === item.position.lng()
      );
      _clusterObjects.push(foundObject);
    });
    setClusterObjects(_clusterObjects);
    setCurrentPage(1);
    setSelectedCluster({
      position: cluster.getCenter(),
    });
  };

  const renderOverlay = (marker: any, clusterer: any, index: number) => (
    <Fragment key={`${index}`}>
      <ObjectMarker
        marker={marker}
        selectedMarkerLoading={selectedMarkerLoading}
        clusterer={clusterer}
        setSelectedCluster={setSelectedCluster}
        setSelectedMarker={setSelectedMarker}
      />
      <OverlayViewF
        position={{ lat: marker.lat, lng: marker.lng }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={getPixelPositionOffset}
      >
        {selectedMarker === marker.uniqueId && (
          <Fragment>
            <MarkerLabel>{marker?.name || "N/A"}</MarkerLabel>
            <MarkerBlip></MarkerBlip>
          </Fragment>
        )}
      </OverlayViewF>
    </Fragment>
  );

  const totalPages = Math.ceil(clusterObjects.length / objectsPerPage);

  const paginatedObjects = clusterObjects.slice(
    (currentPage - 1) * objectsPerPage,
    currentPage * objectsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Fragment>
      {showClusters && markers.length > 1 ? (
        <MarkerClustererF
          options={options}
          minimumClusterSize={5}
          onClick={handleClusterClick}
        >
          {(clusterer) => (
            <Fragment>
              {visibleMarkers &&
                visibleMarkers?.map((marker: any, i: number) =>
                  renderOverlay(marker, clusterer, i)
                )}
            </Fragment>
          )}
        </MarkerClustererF>
      ) : (
        visibleMarkers &&
        visibleMarkers?.map((marker: any, i: number) =>
          renderOverlay(marker, undefined, i)
        )
      )}

      {selectedCluster && showClusters && (
        <OverlayViewF
          position={selectedCluster.position}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}
        >
          <ClusterListing>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item xs={4}>
                <ClusterHeading>
                  Objects ({clusterObjects?.length || 0})
                </ClusterHeading>
              </Grid>
              <Grid mr={1} item xs={1}>
                <IconButton onClick={() => setSelectedCluster(null)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>

            <ObjectList paginatedObjects={paginatedObjects} />

            <Grid container alignItems={"center"} justifyContent={"flex-end"}>
              <Grid m={0.5} item>
                <IconButton
                  disabled={currentPage === 1}
                  onClick={handlePrevPage}
                >
                  <NavigateBefore />
                </IconButton>
              </Grid>
              <Grid>
                <Typography variant="body2">
                  {currentPage} of {totalPages}
                </Typography>
              </Grid>
              <Grid m={0.5} item>
                <IconButton
                  disabled={currentPage === totalPages}
                  onClick={handleNextPage}
                >
                  <NavigateNext />
                </IconButton>
              </Grid>
            </Grid>
          </ClusterListing>
        </OverlayViewF>
      )}
    </Fragment>
  );
};

export default memo(MarkerClusterer);
