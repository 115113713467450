import React from "react";
import {
  AddressText,
  ClusterBoxContainer,
  TitleText,
} from "./MarkerClusterer.styled";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface ObjectListProps {
  paginatedObjects: any;
}

const ObjectList: React.FC<ObjectListProps> = ({ paginatedObjects = [] }) => {
  const navigate = useNavigate();

  return paginatedObjects.map((object: any, index: number) => (
    <ClusterBoxContainer key={`${index}`}>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
        >
          <img
            src={object?.iconUrl}
            width={"24px"}
            height={"24px"}
            alt="obj icon"
          />
          <TitleText
            onClick={() =>
              navigate({
                pathname: `/dashboard/objects/details/${object?.uniqueId}`,
              })
            }
          >
            {object?.name}
          </TitleText>
        </Stack>
        <AddressText>ID {object?.aNumber}</AddressText>
      </Stack>
    </ClusterBoxContainer>
  ));
};

export default ObjectList;
