import { FC, useEffect } from "react";
import { Stack, useTheme } from "@mui/material";
import {
  FormikSelectField,
  FormikSelectSearchField,
  // FormikToggleField, // TODO will be added later
} from "@vilocnv/allsetra-core";
import { filterObjectsByGroup, removeDuplicateObjects } from "app/data/helpers";
import DateAndTimeFilters from "../../DateAndTimeFilters/DateAndTimeFilters";
import RideModeFilters from "../../RideModeFilters/RideModeFilters";

interface Values {
  group: string[];
  object: string[];
}

interface Props {
  groups: Array<any>;
  objectTypes: Array<any>;
  objects: Array<any>;
  keys: Array<any>;
  dataLoading?: boolean;
  translator: any;
  fetchOnDebounce: (value: string) => void;
  onChange?: () => void;
  values: Values;
  setFieldValue: any;
  isCO2InnerForm?: boolean;
  isMultipleRidesForm?: boolean;
  isMileageReport?: boolean;
}

const InnerForm: FC<Props> = ({
  groups,
  objectTypes,
  objects,
  keys,
  dataLoading = false,
  translator,
  fetchOnDebounce,
  onChange,
  values,
  setFieldValue,
  isCO2InnerForm = false,
  isMultipleRidesForm = false,
  isMileageReport = false,
}) => {
  const theme = useTheme();

  useEffect(() => {
    const filteredObjects: Array<any> = filterObjectsByGroup(
      values,
      objects,
      groups
    );
    const uniqueFilteredObjects = removeDuplicateObjects(filteredObjects);
    const newObjectsValues = uniqueFilteredObjects
      .filter((item) => values.object.includes(item.uniqueId))
      .map((item) => item.uniqueId);
    setFieldValue("object", newObjectsValues);
  }, [values.group]);

  return (
    <Stack spacing={2}>
      {isMileageReport ? (
        <RideModeFilters direction="column" translator={translator} />
      ) : null}

      <FormikSelectField
        theme={theme}
        label={translator("groups.label", { ns: "formFieldsTranslation" })}
        placeholder={translator("groups.placeholder", {
          ns: "formFieldsTranslation",
        })}
        name="group"
        options={groups ?? []}
        optionLabelKey="name"
        optionValueKey="uniqueId"
        loading={dataLoading}
        multiple
        searchable
      />
      <FormikSelectSearchField
        theme={theme}
        fetchOnDebounce={fetchOnDebounce}
        label={translator("objects.label", { ns: "formFieldsTranslation" })}
        placeholder={translator("objects.placeholder", {
          ns: "formFieldsTranslation",
        })}
        name="object"
        options={filterObjectsByGroup(values, objects, groups) ?? []}
        optionLabelKey="name"
        optionValueKey="uniqueId"
        loading={dataLoading}
        multiple
        searchable
        onChange={onChange}
        required={isMultipleRidesForm}
      />
      <FormikSelectField
        theme={theme}
        label={translator("objectType.label", { ns: "formFieldsTranslation" })}
        placeholder={translator("objectType.placeholder", {
          ns: "formFieldsTranslation",
        })}
        name="objectType"
        options={objectTypes ?? []}
        optionLabelKey="name"
        optionValueKey="uniqueId"
        loading={dataLoading}
        multiple
        searchable
      />
      <FormikSelectField
        theme={theme}
        label={translator("keys.label", { ns: "formFieldsTranslation" })}
        placeholder={translator("keys.placeholder", {
          ns: "formFieldsTranslation",
        })}
        name="key"
        options={keys ?? []}
        optionLabelKey="label"
        optionValueKey="uniqueId"
        loading={dataLoading}
        multiple
        searchable
      />
      {!isCO2InnerForm && <DateAndTimeFilters />}

      {/* TODO will be aded later  */}
      {/* {isCO2InnerForm && (
        <>
          <RideModeFilters />
          <FormikToggleField
            label={translator("excludeRides.label", {
              ns: "formFieldsTranslation",
            })}
            name="excludeRides"
          />
        </>
      )} */}
    </Stack>
  );
};

export default InnerForm;
