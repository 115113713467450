import { FC, useEffect, useMemo, useState } from "react";
import { Box, useTheme } from "@mui/material";
import {
  Topbar,
  Table,
  useDispatchOnParams,
  DeleteConfirmationModal,
  types,
  AddUserForm,
  toast,
  utils,
} from "@vilocnv/allsetra-core";
import AddIcon from "@mui/icons-material/Add";

// Data
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector, useDispatchOnMount } from "hooks";
import { isEmpty } from "lodash";
import {
  ALL_USERS_TABLE_COLUMNS,
  callAlarmPriorities,
} from "app/data/constants";
import {
  createOrUpdateUserThunk,
  deactivateUserThunk,
  getAllKeysByAccountThunk,
  getAllRolesThunk,
  getAvailableKeysByAccountThunk,
  getGooglePlacesThunk,
  getObjectsByQueryThunk,
  getSpecificUserThunk,
  getUsersByQueryThunk,
  resetGooglePredictatedPlaces,
  resetQueriedObjects,
  resetSpecificUser,
} from "app/features";
import {
  selectAccountKeysState,
  selectAllRoles,
  selectDashboardGooglePlacesState,
  selectDrawerSelectedAccountId,
  selectQueriedObjectsState,
  selectUsersState,
} from "app/data/selectors";
import { FormikHelpers } from "formik";
import { signalRGenerateSuccessToastMessage } from "app/data/helpers";
import { SignalRService } from "app/data/services";
import { getFilteredUsers } from "app/data/helpers/usersHelpers";

const Users: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Global States
  const drawerSelectedAccountId =
    useAppSelector(selectDrawerSelectedAccountId) || "";
  const roles = useAppSelector(selectAllRoles);
  const { loading, allUsers, specificUser, totalUsers } =
    useAppSelector(selectUsersState);
  const { accountKeys, accountAvailableKeys, accountAvailableKeysLoading } =
    useAppSelector(selectAccountKeysState);
  const { objects, loading: objectsLoading } = useAppSelector(
    selectQueriedObjectsState
  );
  const { googlePredictatedPlacesLoading, googlePredictatedPlaces } =
    useAppSelector(selectDashboardGooglePlacesState);

  // Local States
  const [open, setOpen] = useState(false); // Used for Add User Modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Used for DeleteConfirmationModal Modal
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null); // Used for storing the User id of the selected user
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation([
    "translation",
    "tableHeadingsTranslation",
    "formFieldsTranslation",
  ]);

  useDispatchOnParams(getUsersByQueryThunk, {
    args: { accountId: drawerSelectedAccountId },
    searchByField: "user.email",
  });

  useDispatchOnMount(getAllRolesThunk, roles.length ? undefined : true);

  useDispatchOnMount(getAllKeysByAccountThunk, drawerSelectedAccountId);

  useDispatchOnMount(getAvailableKeysByAccountThunk, drawerSelectedAccountId);

  useEffect(() => {
    !open && dispatch(resetSpecificUser());
  }, [open]);

  const formValues = useMemo(
    () => (selectedUserId && !isEmpty(specificUser) ? specificUser : null),
    [selectedUserId, specificUser]
  );

  const tableColumns = useMemo(
    () => ALL_USERS_TABLE_COLUMNS(roles, t),
    [roles, t]
  );

  const allFilteredUsers = useMemo(
    () => getFilteredUsers(allUsers, roles),
    [roles, allUsers, t]
  );

  const filteredKeys = useMemo(() => {
    if (isEmpty(formValues)) return accountAvailableKeys;

    const formKey =
      accountKeys &&
      accountKeys.find((key: any) => {
        // @ts-ignore
        return formValues.key === key?.uniqueId;
      });

    return isEmpty(formKey)
      ? accountAvailableKeys
      : [...accountAvailableKeys, formKey];
  }, [accountKeys, accountAvailableKeys, formValues]);

  const openDeleteConfirmationModal = (user: types.IUser) => {
    setSelectedUserId(user.uniqueId);
    setOpenDeleteModal(true);
  };

  const handleAssignedObjectsSearch = (value: string) => {
    dispatch(
      getObjectsByQueryThunk({
        accountId: drawerSelectedAccountId || "",
        params: {
          itemsPerPage: 50,
          page: 1,
          where: [{ field: "name", value, type: 0 }],
        },
      })
    );
  };

  const onDeleteUserFulfilled = async () => {
    setOpenDeleteModal(false);
    setIsDeleting(false);

    const user = allUsers.find((user) => user.uniqueId === selectedUserId);
    const userName = user ? `${user.firstName} ${user.lastName}` : null;

    toast.success(
      userName
        ? signalRGenerateSuccessToastMessage(userName, "User", "deactivated")
        : "User has been successfully deactivated"
    );

    await dispatch(
      getUsersByQueryThunk({
        params: utils.getCommonParamsForApi(),
        accountId: drawerSelectedAccountId ?? "",
      })
    );
  };

  const handleDeleteUser = async () => {
    if (!selectedUserId) return;

    setIsDeleting(true);

    const { type } = await dispatch(
      deactivateUserThunk({
        accountId: drawerSelectedAccountId,
        userId: selectedUserId,
      })
    );

    if (type === "users/deactivateUserThunk/fulfilled") {
      // Set a timeout to stop listening after 10 seconds
      const timeoutId = setTimeout(() => {
        SignalRService.hubConnection?.off("EventRaised");
        onDeleteUserFulfilled();
        console.log("Stopped listening for EventRaised after 10 seconds.");
      }, 10000);

      const handleEventRaised = (event: any) => {
        if (
          event.eventName ===
          types.BackendEventsEnum.UserRemovedFromAccountEvent
        ) {
          onDeleteUserFulfilled();

          // Clear the timeout if the event is received
          clearTimeout(timeoutId);

          // Remove the listener if it's a one-time use
          SignalRService.hubConnection?.off("EventRaised", handleEventRaised);
        }
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      setIsDeleting(false);
    }
  };

  const handleUpdateUser = async (user: types.IUser) => {
    if (!user) return;

    dispatch(resetGooglePredictatedPlaces());
    dispatch(resetQueriedObjects());

    dispatch(
      getSpecificUserThunk({
        accountId: drawerSelectedAccountId,
        userId: user.uniqueId,
      })
    );

    setSelectedUserId(user.uniqueId);
    setOpen(true);
  };

  const createOrUpdateUserFulfilled = async (
    values: types.IAddUser,
    formikHelpers: FormikHelpers<types.IAddUser>
  ) => {
    setOpen(false);
    formikHelpers.resetForm();
    formikHelpers.setSubmitting(false);
    setIsSubmitting(false);

    toast.success(
      `User has been ${values?.uniqueId ? "updated" : "created"} successfully.`
    );

    await dispatch(
      getUsersByQueryThunk({
        params: utils.getCommonParamsForApi(),
        accountId: drawerSelectedAccountId ?? "",
      })
    );
  };

  const addUserHandler = async (
    values: types.IAddUser,
    formikHelpers: FormikHelpers<types.IAddUser>
  ) => {
    formikHelpers.setSubmitting(true);
    setIsSubmitting(true);

    const { type } = await dispatch(
      createOrUpdateUserThunk({
        accountId: drawerSelectedAccountId,
        data: { ...values, phone: `${values.countryCode}${values.phone}` },
      })
    );

    if (type === "users/createOrUpdateUserThunk/fulfilled") {
      // Set a timeout to stop listening after 10 seconds
      const timeoutId = setTimeout(() => {
        SignalRService.hubConnection?.off("EventRaised");
        createOrUpdateUserFulfilled(values, formikHelpers);
        console.log("Stopped listening for EventRaised after 10 seconds.");
      }, 10000);

      const handleEventRaised = (event: any) => {
        if (
          event.eventName ===
            types.BackendEventsEnum.UserAssignedToAccountEvent ||
          event.eventName === types.BackendEventsEnum.UserUpdatedEvent ||
          event.eventName === types.BackendEventsEnum.AccountUserUpdatedEvent
        ) {
          createOrUpdateUserFulfilled(values, formikHelpers);

          // Clear the timeout if the event is received
          clearTimeout(timeoutId);

          // Remove the listener if it's a one-time use
          SignalRService.hubConnection?.off("EventRaised", handleEventRaised);
        }
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      formikHelpers.setSubmitting(false);
      setIsSubmitting(false);
    }
  };

  return (
    <Box>
      <Topbar
        theme={theme}
        title={t("drawerMenuLinks.users")}
        primaryButton={{
          id: "user-add",
          variant: "outlined",
          text: `${t("titles.addUser")}`,
          startIcon: <AddIcon />,
          onClick: () => {
            dispatch(resetGooglePredictatedPlaces());
            setOpen(true);
          },
        }}
      />
      <Box mx={4}>
        <Table
          columns={tableColumns}
          data={allFilteredUsers}
          progressPending={loading}
          paginationTotalRows={totalUsers}
          searchPlaceholder={t("common.search")}
          cellActions={[
            {
              name: `${t("common.user")} ${t("buttonsText.edit")}`,
              onClick: handleUpdateUser,
            },
            {
              name: `${t("common.user")} ${t("buttonsText.deactivate")}`,
              when: (row: types.IUser) => row.isDeleted === false,
              onClick: openDeleteConfirmationModal,
            },
          ]}
        />
      </Box>
      <AddUserForm
        modalStyle={{
          "& div:nth-child(3)": {
            "& div:nth-child(3)": {
              overflowX: "hidden",
              "&::-webkit-scrollbar": {
                width: "6px !important",
                backgroundColor: "transparent !important",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 5px grey",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "grey",
                borderRadius: "10px",
              },
            },
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
        initialValues={formValues}
        onSubmit={addUserHandler}
        roles={roles}
        keys={filteredKeys ?? []}
        keysLoading={accountAvailableKeysLoading}
        fetchObjectsOnDebounce={handleAssignedObjectsSearch}
        searchedObjects={objects}
        objectsLoading={objectsLoading}
        alarmPriority={callAlarmPriorities}
        theme={theme}
        submitting={isSubmitting}
        googlePredictatedPlacesLoading={googlePredictatedPlacesLoading}
        googlePredictatedPlaces={googlePredictatedPlaces}
        debounceFetchPlaces={(search: string) => {
          dispatch(getGooglePlacesThunk(search));
        }}
        translator={t}
      />
      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title="You are about to delete user"
        subTitle="Do you really want to delete this user? This process cannot be undone."
        theme={theme}
        primaryBtnProps={{
          id: "user-delete",
          onClick: handleDeleteUser,
          loading: isDeleting,
        }}
      />
    </Box>
  );
};

export default Users;
