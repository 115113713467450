import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, toast, types } from "@vilocnv/allsetra-core";
import Map from "components/maps/Map/Map";
import {
  AddIconWrapper,
  ObjectMapContainer,
  ObjectMapWrapper,
  RadiusSelectionBox,
  RadiusSelectionItemsWrapper,
  SelectRadiusTextBox,
} from "../ObjectDetailsSection.styled";
import { CircleF } from "@react-google-maps/api";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  createOrEditGeozoneThunk,
  getAllGeozonesThunk,
  setActiveGeozoneId,
  setActiveObjectAsMarker,
  updateGeozoneGeoJsonById,
} from "app/features";
import { AddGreenIcon, CancelRoundIcon } from "assets/icons";
import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import GeozoneForm from "components/forms/GeozoneForm/GeozoneForm";
import {
  selectActiveGeozoneId,
  selectDrawerSelectedAccountId,
  selectGeozonesState,
} from "app/data/selectors";
import { IAddGeozone } from "app/data/types";
import { FormikHelpers } from "formik";
import {
  createGeozoneObjectForNewlyDrawedCircle,
  signalRGenerateSuccessToastMessage,
} from "app/data/helpers";
import { SignalRService } from "app/data/services";
import { geozoneRadiusOptions, POLYGON_OPTIONS } from "app/data/constants";
import { isEmpty, isObject } from "lodash";

import { useTranslation } from "react-i18next";

interface Props {
  activeObject: types.IObject | null;
}

const ObjectDetailsMap: FC<Props> = ({ activeObject }) => {
  const { t } = useTranslation(["translation"]);
  const dispatch = useAppDispatch();

  const objectLocation = useMemo(
    () => ({
      lat: activeObject?.location?.latitude || 0,
      lng: activeObject?.location?.longitude || 0,
    }),
    [activeObject]
  );

  const activeGeozoneId = useAppSelector(selectActiveGeozoneId);

  const { allGeozones } = useAppSelector(selectGeozonesState);

  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);

  const [circleSize, setCircleSize] = useState(10000);
  const [geozoneFormOpen, setGeozoneFormOpen] = useState(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [circle, setCircle] = useState<null>(null);
  //@ts-ignore
  const [createCircle, setCreateCircle] = useState(false);

  const circleRef = useRef();

  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent) => {
    if (setCircleSize) setCircleSize(Number(event.target.value));
  };

  const onCircleEdit = useCallback(() => {
    if (circleRef.current) {
      const geoJson = JSON.stringify(
        createGeozoneObjectForNewlyDrawedCircle(circleRef.current)
      );

      dispatch(
        updateGeozoneGeoJsonById({ id: activeGeozoneId || "", geoJson })
      );
    }
  }, [allGeozones, activeGeozoneId, circleRef]);

  const onCircleLoad = useCallback(
    (circle: any, apiRadius: number) => {
      circleRef.current = circle;
      setCircle(circle);
      const center = circle.getCenter();
      setCircleSize && setCircleSize(apiRadius);
    },
    [onCircleEdit, circleRef]
  );

  const onFulfilledRequest = async (
    name: string,
    formikHelpers: FormikHelpers<IAddGeozone>
  ) => {
    formikHelpers.setSubmitting(false);
    formikHelpers.resetForm();
    setSubmitting(false);
    onCloseGeozoneForm();

    toast.success(
      signalRGenerateSuccessToastMessage(name, "Geozone", "created")
    );

    setCreateCircle(false);

    await dispatch(getAllGeozonesThunk(drawerSelectedAccountId || ""));
    await dispatch(setActiveGeozoneId(null));
  };

  const onSubmitHandler = async (
    values: IAddGeozone,
    formikHelpers: FormikHelpers<IAddGeozone>
  ) => {
    formikHelpers.setSubmitting(true);
    setSubmitting(true);

    //@ts-ignore
    const geozone = createGeozoneObjectForNewlyDrawedCircle(circle);

    const { type } = await dispatch(
      createOrEditGeozoneThunk({
        accountId: drawerSelectedAccountId,
        data: {
          ...values,
          assignedObjects: values.assignedToAllObjects
            ? []
            : values.assignedObjects,
          geoJson: geozone?.geoJson,
          isEnabled: true,
        },
      })
    );

    if (type === "geozones/createOrEditGeozoneThunk/fulfilled") {
      // Set a timeout to stop listening after 10 seconds
      const timeoutId = setTimeout(() => {
        SignalRService.hubConnection?.off("EventRaised");
        onFulfilledRequest(values.name, formikHelpers);
        console.log("Stopped listening for EventRaised after 10 seconds.");
      }, 10000);

      const handleEventRaised = (event: any) => {
        if (
          event.eventName ===
            types.BackendEventsEnum.AccountGeozoneCreatedEvent ||
          event.eventName === types.BackendEventsEnum.AccountGeozoneUpdatedEvent
        ) {
          onFulfilledRequest(event.name, formikHelpers);

          // Clear the timeout if the event is received
          clearTimeout(timeoutId);

          // Remove the listener if it's a one-time use
          SignalRService.hubConnection?.off("EventRaised", handleEventRaised);
        }
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      formikHelpers.setSubmitting(false);
      setSubmitting(false);
    }
  };

  const toggleGeozoneFormOpen = () => {
    setGeozoneFormOpen(!geozoneFormOpen);
  };

  const onCloseGeozoneForm = () => {
    toggleGeozoneFormOpen();
  };

  const renderGeozones = () => {
    return (
      allGeozones &&
      allGeozones.map((geozone) => {
        const geoJSON = JSON.parse(geozone.geoJson);

        if (!isObject(geoJSON)) return null;

        //@ts-ignore
        const geoJSONProperties = geoJSON?.properties || geoJSON?.Properties;

        const editMode = geozone.uniqueId === activeGeozoneId;

        if (!isEmpty(geoJSONProperties)) {
          const centerPoint =
            geoJSONProperties.centerPoint || geoJSONProperties.CenterPoint;

          const APIRadius =
            geoJSONProperties.radius || geoJSONProperties.Radius;

          if (!APIRadius || !centerPoint) return null;

          const center = {
            lat: centerPoint[0] || 0,
            lng: centerPoint[1] || 0,
          };

          return (
            <React.Fragment key={geozone.uniqueId}>
              <CircleF
                options={{
                  ...POLYGON_OPTIONS,
                }}
                radius={APIRadius}
                center={center}
                onLoad={(circle) => {
                  if (editMode) onCircleLoad(circle, APIRadius);
                }}
                onUnmount={() => setCircle(null)}
              />
            </React.Fragment>
          );
        }
      })
    );
  };

  const objectRadius = useMemo(
    () =>
      activeObject?.location?.accuracy
        ? activeObject.location.accuracy > 15
          ? activeObject.location.accuracy
          : 0
        : 0,
    [activeObject]
  );

  useEffect(() => {
    if (activeObject) {
      dispatch(setActiveObjectAsMarker(activeObject));
      dispatch(getAllGeozonesThunk(drawerSelectedAccountId || ""));
    }
  }, []);

  return (
    <ObjectMapContainer>
      <ObjectMapWrapper>
        <Map
          center={objectLocation}
          zoom={10}
          radius={objectRadius}
          objects={[activeObject]}
          geozones={[objectLocation]}
          height="75vh"
          objectsMarker
          selectedObjectId={activeObject?.uniqueId}
        >
          {renderGeozones()}
          {createCircle && (
            <CircleF
              options={POLYGON_OPTIONS}
              radius={circleSize}
              center={objectLocation}
              onLoad={(circle) => {
                //@ts-ignore
                setCircle(circle);
              }}
              onUnmount={() => setCircle(null)}
            />
          )}
        </Map>
        <AddIconWrapper>
          <Box sx={{ marginTop: "26px" }}>
            {createCircle ? (
              <CancelRoundIcon
                style={{ cursor: "pointer" }}
                onClick={() => setCreateCircle(false)}
              />
            ) : (
              <AddGreenIcon
                style={{ cursor: "pointer" }}
                onClick={() => setCreateCircle(true)}
              />
            )}
          </Box>
          {createCircle && (
            <RadiusSelectionBox>
              <RadiusSelectionItemsWrapper>
                <Select
                  id="circle-dropdown"
                  variant={"standard"}
                  value={circleSize?.toString()}
                  label="Size"
                  onChange={handleChange}
                  sx={{ fontSize: "14px" }}
                  disableUnderline={true}
                >
                  {geozoneRadiusOptions.map((radiusItem) => {
                    return (
                      <MenuItem key={radiusItem.label} value={radiusItem.value}>
                        {radiusItem.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Button
                  id="submit-button"
                  theme={theme}
                  size={"small"}
                  text={t("buttonsText.save")}
                  variant="text"
                  sx={{ color: "#845AFC", fontSize: "14px" }}
                  onClick={() => {
                    setGeozoneFormOpen(true);
                  }}
                />
              </RadiusSelectionItemsWrapper>
              <SelectRadiusTextBox>
                {t("descriptions.selectGeozoneRadius")}
              </SelectRadiusTextBox>
            </RadiusSelectionBox>
          )}
          <Box></Box>
        </AddIconWrapper>
      </ObjectMapWrapper>
      <GeozoneForm
        open={geozoneFormOpen}
        onClose={onCloseGeozoneForm}
        onSubmitHandler={onSubmitHandler}
        submitting={submitting}
        activeGeozoneId={activeGeozoneId}
        activeObjectId={activeObject}
        isObjectDetailsPage={true}
      />
    </ObjectMapContainer>
  );
};

export default ObjectDetailsMap;
