import { FC, useMemo } from "react";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import StepScreenLayout from "components/layouts/StepScreenLayout/StepScreenLayout";
import InnerForm from "./children/InnerForm";
import { Grid } from "@mui/material";

//Data
import {
  installationDataFormatter,
  installationInitialValues,
  installationsValidationSchema,
} from "app/data/helpers/activationPortalHelpers";
import { useAppSelector } from "hooks";
import { selectActivationPortalState } from "app/data/selectors/activationPortalSelectors";
import { PageLoader, useDispatchOnParams } from "@vilocnv/allsetra-core";
import { getInstallationThunk } from "app/features";
import { useTranslation } from "react-i18next";

interface Props {
  gotoNextStep: () => void;
}

const ActivationInstallationForm: FC<Props> = ({ gotoNextStep }) => {
  //Global States
  const { installations, loading, verificationCode } = useAppSelector(
    selectActivationPortalState
  );

  const { t } = useTranslation([
    "translation",
    "activationPortal",
    "formFieldsTranslation",
  ]);

  useDispatchOnParams(getInstallationThunk, {
    args: { activationCode: verificationCode },
  });

  const onSubmitHandler = () => {
    gotoNextStep();
  };

  const formValues = useMemo(
    () =>
      !isEmpty(installations)
        ? installationDataFormatter(installations)
        : installationInitialValues,
    [installations]
  );

  return (
    <Formik
      initialValues={formValues}
      onSubmit={onSubmitHandler}
      validationSchema={installationsValidationSchema(t)}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isValid }) => (
        <Form>
          {loading ? (
            <PageLoader />
          ) : (
            <Grid container>
              <Grid item xs={12} md={12}>
                <StepScreenLayout
                  title={t("titles.installationDetails", {
                    ns: "activationPortal",
                  })}
                  subtitle={t("descriptions.installationDetails", {
                    ns: "activationPortal",
                  })}
                  disabled={!isValid}
                  onButtonSubmit={handleSubmit}
                >
                  <InnerForm installations={installations} translator={t} />
                </StepScreenLayout>
              </Grid>
            </Grid>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default ActivationInstallationForm;
