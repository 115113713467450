import { FC } from "react";
import { Stack, FormLabel, Box } from "@mui/material";
import { Label, Checkbox } from "./RideModeFilters.styled";
import { RIDE_MODES } from "app/data/constants";

interface Props {
  translator: any;
  direction: "row" | "column";
}

const RideModeFilters: FC<Props> = ({ translator, direction }) => {
  return (
    <Stack spacing={2}>
      <FormLabel
        sx={{
          fontSize: "14px",
        }}
      >
        {translator("rideMode", { ns: "formFieldsTranslation" })}
      </FormLabel>
      <Box sx={{ display: "flex", flexDirection: direction }}>
        {RIDE_MODES.map((item: any, i: number) => (
          <Box
            mb={direction === "column" ? 1 : 0}
            ml={direction === "row" && i !== 0 ? 3 : 0}
          >
            <Label key={`${i}`}>
              <Checkbox type="checkbox" name="rideModes" value={item.value} />
              {translator(`rideModesTypes.${item.name}`, {
                ns: "formFieldsTranslation",
              })}
            </Label>
          </Box>
        ))}
      </Box>
    </Stack>
  );
};

export default RideModeFilters;
