export const numberRegex = /^[0-9]+$/;

export const isNumber = ({ value }: { value: string }) => {
  return !!value && numberRegex.test(value);
};

//Regex
export const phoneRegExp =
  /^[+]?((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const emailRegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passowrdRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const nameRegex = /^[A-Za-z-\s]+$/;
export const lastNameRegex = /^[A-Za-z-\s]+$/;
export const alphanumericRegex = /^[A-Za-z0-9-]+$/;
export const streetRegex = /^[A-Za-z0-9-\s]+$/;
export const safetyQsAnsRegex = /^[A-Za-z0-9-\s]+$/;
export const vatRegex = /^NL\d{9}B\d{2}$/;
export const cityRegex = /^[A-Za-z-\s]+$/;

export function isObject(value: any) {
  return typeof value === "object" && value !== null && !Array.isArray(value);
}

export const getElementSize = (
  element: HTMLElement | undefined
): { width: number; height: number } => {
  if (!element) return { width: 0, height: 0 };

  return { width: element.offsetWidth, height: element.offsetHeight };
};
