import { FC, useEffect, useMemo, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import { toast, Topbar, types } from "@vilocnv/allsetra-core";
import { isEmpty, omit } from "lodash";

//Children
import PersonalInfoForm from "./children/PersonalInfoForm";
import InterfaceForm from "./children/InterfaceForm";

//Data
import { useTranslation } from "react-i18next";
import {
  signalRGenerateSuccessToastMessage,
  userSettingsFormatterForForm,
  userSettingsValidationSchema,
} from "app/data/helpers";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  selectAccountsState,
  selectDrawerSelectedAccountId,
  selectLanguageState,
  selectSettingsState,
  selectUserRolesState,
} from "app/data/selectors";
import { ISettings } from "app/data/types";
import {
  getAllSafetyQuestionsThunk,
  getSpecificAccountUserThunk,
  updateSettingsThunk,
  getSpecificSettingThunk,
} from "app/features";
// import NotificationForm from "./children/NotificationForm";
import WorkingHoursForm from "./children/WorkingHoursForm";
import { SignalRService } from "app/data/services";

const UpdateSettingsForm: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //Global States
  const { specificSetting } = useAppSelector(selectSettingsState);
  const { userRoles } = useAppSelector(selectUserRolesState);
  const { languagesLoading, languages } = useAppSelector(selectLanguageState);
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);
  const { accountUser } = useAppSelector(selectAccountsState);
  const { safetyQuestions } = useAppSelector(selectSettingsState);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const { t, i18n } = useTranslation(["translation", "formFieldsTranslation"]);

  useEffect(() => {
    dispatch(getAllSafetyQuestionsThunk());
  }, []);

  const formValues = useMemo(
    () =>
      !isEmpty(specificSetting)
        ? userSettingsFormatterForForm(
            specificSetting,
            accountUser,
            safetyQuestions,
            userRoles
          )
        : {},
    [specificSetting, accountUser, safetyQuestions, userRoles]
  );

  useEffect(() => {
    if (drawerSelectedAccountId !== null) {
      dispatch(
        getSpecificAccountUserThunk({
          accountId: drawerSelectedAccountId,
          //@ts-ignore
          userId: specificSetting?.uniqueId,
        })
      );
    }
  }, [drawerSelectedAccountId]);

  const onFulfilledRequest = (formikHelpers: FormikHelpers<ISettings>) => {
    formikHelpers.setSubmitting(false);
    setFormSubmitting(false);

    toast.success("The user settings has been updated");

    dispatch(getSpecificSettingThunk());
  };

  const handleUserSettings = async (
    values: ISettings,
    formikHelpers: FormikHelpers<ISettings>
  ) => {
    formikHelpers.setSubmitting(true);
    setFormSubmitting(true);

    const formattedData = omit(values, [
      "role",
      "uniqueId",
      "securityQuestion",
      "notifications",
    ]);

    if (values.securityQuestionId.length < 1) {
      toast.error("Security Question Required!");
      formikHelpers.setSubmitting(false);
      setFormSubmitting(false);
    } else {
      const { type } = await dispatch(
        updateSettingsThunk({ ...formattedData })
      );

      if (type === "settings/updateSettingsThunk/fulfilled") {
        const selectedLang = languages.find(
          (lang) => lang.languageId === values.languageId
        );

        i18n.changeLanguage(selectedLang?.languageCode || "en");

        // Set a timeout to stop listening after 10 seconds
        const timeoutId = setTimeout(() => {
          SignalRService.hubConnection?.off("EventRaised");
          onFulfilledRequest(formikHelpers);
          console.log("Stopped listening for EventRaised after 10 seconds.");
        }, 10000);

        const handleEventRaised = (event: any) => {
          if (event.eventName === types.BackendEventsEnum.UserUpdatedEvent) {
            onFulfilledRequest(formikHelpers);

            // Clear the timeout if the event is received
            clearTimeout(timeoutId);

            // Remove the listener if it's a one-time use
            SignalRService.hubConnection?.off("EventRaised", handleEventRaised);
          }
        };

        SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
      } else {
        formikHelpers.setSubmitting(false);
        setFormSubmitting(false);
      }
    }
  };

  return (
    <Formik
      //@ts-ignore
      initialValues={formValues}
      onSubmit={handleUserSettings}
      validationSchema={userSettingsValidationSchema}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isSubmitting, dirty, isValid }) => (
        <Form>
          <Topbar
            theme={theme}
            title={t("titles.userSettings")}
            primaryButton={{
              id: "settings-change",
              variant: "contained",
              text: t("buttonsText.saveChanges"),
              onClick: handleSubmit,
              loading: isSubmitting || formSubmitting,
              disabled: !dirty ? isValid : !isValid,
            }}
            secondaryButton={{
              id: "settings-cancel",
              variant: "text",
              text: t("buttonsText.cancel"),
              onClick: () => {
                navigate(-1);
              },
            }}
          />
          <Box px={"32px"}>
            <PersonalInfoForm />
            {/* <NotificationForm /> */}
            <WorkingHoursForm />
            <InterfaceForm
              languages={languages}
              languagesLoading={languagesLoading}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateSettingsForm;
