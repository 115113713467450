import { FC, useMemo, useState } from "react";
import { types, ModalProps, toast } from "@vilocnv/allsetra-core";
import { Form, Formik, FormikHelpers } from "formik";
import InnerForm from "./children/InnerForm";

// Data
import { isEmpty, pick } from "lodash";
import {
  signalRGenerateSuccessToastMessage,
  transformObjectRideDataForForm,
  updateObjectRideFormValidationSchema,
} from "app/data/helpers";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getObjectSpecificRideInfoThunk,
  updateObjectRideThunk,
} from "app/features";
import { selectDrawerSelectedAccountId } from "app/data/selectors";
import { SignalRService } from "app/data/services";

type Props = Omit<ModalProps, "title" | "children"> & {
  objectRide: types.IObjectRide | null;
};

const UpdateObjectRideForm: FC<Props> = ({ open, onClose, objectRide }) => {
  const dispatch = useAppDispatch();

  const accountId = useAppSelector(selectDrawerSelectedAccountId);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const initialValues = useMemo(
    () =>
      !isEmpty(objectRide) ? transformObjectRideDataForForm(objectRide) : {},
    [objectRide]
  );

  const onFulfilledRequest = (
    params: any,
    formikHelpers: FormikHelpers<types.IUpdateObjectRide>
  ) => {
    formikHelpers.setSubmitting(false);
    setIsSubmitting(false);

    toast.success("Object ride has been updated.");

    dispatch(getObjectSpecificRideInfoThunk(params));
  };

  const saveChangesHandler = async (
    values: types.IUpdateObjectRide,
    formikHelpers: FormikHelpers<types.IUpdateObjectRide>
  ) => {
    try {
      formikHelpers.setSubmitting(true);
      setIsSubmitting(true);

      const params: { accountId: string; objectId: string; rideId: string } = {
        accountId: accountId ?? "",
        objectId: objectRide?.object?.uniqueId ?? "",
        rideId: objectRide?.uniqueId ?? "",
      };

      const {
        startAddressPointOfInterest,
        correctedStartAddress,
        correctedStartLatitude,
        correctedStartLongitude,
        endAddressPointOfInterest,
        correctedEndAddress,
        correctedEndLatitude,
        correctedEndLongitude,
      } = values;
      const pickedValues = ["uniqueId", "comments"];
      if (values.keyId) {
        pickedValues.push("keyId");
      }
      const data = {
        rideMode: Number(values.rideMode),
        ...pick(values, pickedValues),
        ...(!isEmpty(startAddressPointOfInterest)
          ? { startAddressPointOfInterest }
          : {
              correctedStartAddress,
              correctedStartLatitude,
              correctedStartLongitude,
            }),
        ...(!isEmpty(endAddressPointOfInterest)
          ? { endAddressPointOfInterest }
          : {
              correctedEndAddress,
              correctedEndLatitude,
              correctedEndLongitude,
            }),
      };

      const { type } = await dispatch(
        updateObjectRideThunk({ ...params, data })
      );

      if (type === "objects/updateObjectRideThunk/fulfilled") {
        // Set a timeout to stop listening after 10 seconds
        const timeoutId = setTimeout(() => {
          SignalRService.hubConnection?.off("EventRaised");
          onFulfilledRequest(params, formikHelpers);
          console.log("Stopped listening for EventRaised after 10 seconds.");
        }, 10000);

        const handleEventRaised = (event: any) => {
          if (event.eventName === types.BackendEventsEnum.RideUpdatedEvent) {
            onFulfilledRequest(params, formikHelpers);

            // Clear the timeout if the event is received
            clearTimeout(timeoutId);

            // Remove the listener if it's a one-time use
            SignalRService.hubConnection?.off("EventRaised", handleEventRaised);
          }
        };

        SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
      } else {
        formikHelpers.setSubmitting(false);
        setIsSubmitting(false);
      }
    } catch (e: any) {
      setIsSubmitting(false);
    }
  };

  return (
    <Formik
      // @ts-ignore
      initialValues={initialValues}
      validationSchema={updateObjectRideFormValidationSchema}
      onSubmit={saveChangesHandler}
      enableReinitialize
      validateOnMount
    >
      <Form>
        <InnerForm
          open={open}
          onClose={onClose}
          customIsSubmitting={isSubmitting}
        />
      </Form>
    </Formik>
  );
};

export default UpdateObjectRideForm;
