import { FC, useMemo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik, FormikHelpers } from "formik";
import { useTheme } from "@mui/material";
import { Topbar, types, toast, PageLoader } from "@vilocnv/allsetra-core";
import { capitalize, isEmpty } from "lodash";
import AccountSettingsSection from "components/sections/AccountSettingsSection/AccountSettingsSection";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import { selectActiveAccountState } from "app/data/selectors";
import {
  updateAccountThunk,
  getSpecificAccountThunk,
  resetActiveAccountState,
} from "app/features";
import {
  accountSettingsFormatterForForm,
  accountSettingsValidationSchema,
  signalRGenerateSuccessToastMessage,
} from "app/data/helpers";
import { useTranslation } from "react-i18next";
import { SignalRService } from "app/data/services";

const AccountDetails: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const [formSubmitting, setFormSubmitting] = useState(false);

  // Global State
  const {
    specificAccount,
    activeAccountId,
    loading: isFetching,
  } = useAppSelector(selectActiveAccountState);

  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  const getSpecificAccountById = async () => {
    try {
      const { type } = await dispatch(getSpecificAccountThunk(params.id ?? ""));

      if (type !== "accounts/getSpecificAccountThunk/fulfilled") {
        navigate("/dashboard/accounts");
        toast.error("Account was not found");
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isEmpty(params.id)) {
      dispatch(resetActiveAccountState());
      navigate("/dashboard/accounts");
    } else {
      getSpecificAccountById();
    }
  }, []);

  const initialValues = useMemo(
    () =>
      !isEmpty(specificAccount)
        ? accountSettingsFormatterForForm(specificAccount)
        : {},
    [specificAccount, activeAccountId]
  );

  const onFulfilledRequest = (
    accountName: string,
    formikHelpers: FormikHelpers<types.IAccount>
  ) => {
    getSpecificAccountById();
    formikHelpers.setSubmitting(false);
    setFormSubmitting(false);
    toast.success(
      signalRGenerateSuccessToastMessage(accountName, "Account", "updated")
    );
  };

  const saveChangesHandler = async (
    values: types.IAccount,
    formikHelpers: FormikHelpers<types.IAccount>
  ) => {
    formikHelpers.setSubmitting(true);
    setFormSubmitting(true);

    const { type } = await dispatch(
      updateAccountThunk({ accountId: values?.uniqueId, data: values })
    );

    if (type === "accounts/updateAccountThunk/fulfilled") {
      // Set a timeout to stop listening after 10 seconds
      const timeoutId = setTimeout(() => {
        SignalRService.hubConnection?.off("EventRaised");
        onFulfilledRequest(values.name, formikHelpers);
        console.log("Stopped listening for EventRaised after 10 seconds.");
      }, 10000);

      const handleEventRaised = (event: any) => {
        if (event.eventName === types.BackendEventsEnum.AccountUpdatedEvent) {
          onFulfilledRequest(event.name, formikHelpers);

          // Clear the timeout if the event is received
          clearTimeout(timeoutId);

          // Remove the listener if it's a one-time use
          SignalRService.hubConnection?.off("EventRaised", handleEventRaised);
        }
      };

      SignalRService.hubConnection?.on("EventRaised", handleEventRaised);
    } else {
      formikHelpers.setSubmitting(false);
      setFormSubmitting(false);
    }
  };

  return (
    <main>
      {isFetching ? (
        <PageLoader />
      ) : (
        <Formik
          // @ts-ignore
          initialValues={initialValues}
          validationSchema={accountSettingsValidationSchema}
          onSubmit={saveChangesHandler}
          enableReinitialize
          validateOnMount
        >
          {({ handleSubmit, isSubmitting, isValid, dirty, resetForm }) => (
            <Form>
              <Topbar
                theme={theme}
                title="Account"
                breadcrumbTitle={capitalize(specificAccount?.name) ?? "Go Back"}
                breadcrumbRedirectTo={() => navigate(-1)}
                primaryButton={{
                  variant: "contained",
                  text: t("buttonsText.saveChanges"),
                  onClick: handleSubmit,
                  loading: isSubmitting || formSubmitting,
                  disabled: !dirty ? isValid : !isValid,
                }}
                secondaryButton={{
                  variant: "text",
                  text: t("buttonsText.cancel"),
                  onClick: () => resetForm(),
                }}
              />
              <AccountSettingsSection />
            </Form>
          )}
        </Formik>
      )}
    </main>
  );
};

export default AccountDetails;
