import { FC } from "react";
import { useTheme } from "@mui/material";

const ActiveReportsIcon: FC = () => {
  const theme = useTheme();

  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1665 5.83333V14.1667H7.99984V2.5H13.8332M17.1665 5.83333L13.8332 2.5M17.1665 5.83333H13.8332V2.5M15.4998 14.1667V15.8333H6.33317V4.16667H7.99984M13.8332 15.8333V17.5H4.6665V5.83333H6.33317"
        stroke={theme.palette.primary.main}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ActiveReportsIcon;
