import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import { MapObjectCard, ObjectMetadataTags } from "@vilocnv/allsetra-core";

// Data
import { isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "hooks";
import { setActiveObjectId } from "app/features";
import { selectSpecificObjectState } from "app/data/selectors";
import { SkeletonWrapper, Skeleton } from "./ObjectMarker.styled";

function createKey(location: any) {
  return location.lat + location.lng;
}

interface Props {
  marker: any;
  selectedMarkerLoading: boolean;
  clusterer: any;
  setSelectedCluster: (clusterData: any) => void;
  setSelectedMarker: (id: string) => void;
}

const ObjectMarker: FC<Props> = ({
  marker,
  selectedMarkerLoading,
  clusterer,
  setSelectedCluster,
  setSelectedMarker,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Global State
  const { activeObjectId, markerLoading, selectedMarker } = useAppSelector(
    selectSpecificObjectState
  );

  function formatDate(date: any) {
    if (!date) return "-";

    return new Date(date).toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
  }

  const handleIconClick = () => {
    dispatch(setActiveObjectId(marker.uniqueId ?? ""));
    setSelectedCluster(null);
  };

  const handleCloseMarker = () => {
    dispatch(setActiveObjectId(null));
    window.localStorage.removeItem("selectedObjectId");
  };

  return (
    <MarkerF
      key={createKey(marker)}
      position={{ lat: marker.lat || 0, lng: marker.lng || 0 }}
      clusterer={clusterer}
      onClick={handleIconClick}
      onMouseOver={() => setSelectedMarker(marker?.uniqueId)}
      onMouseOut={() => setSelectedMarker("")}
      icon={
        marker.iconUrl
          ? {
              url: marker.iconUrl,
              scaledSize: new window.google.maps.Size(34, 34),
            }
          : undefined
      }
    >
      {activeObjectId === marker.uniqueId && (
        <InfoWindowF
          position={{ lat: marker.lat || 0, lng: marker.lng || 0 }}
          onCloseClick={handleCloseMarker}
        >
          <MapObjectCard
            label={marker.name || "N/A"}
            id={marker.aNumber || "N/A"}
            loading={false}
            address={
              !isEmpty(selectedMarker)
                ? // @ts-ignore
                  (selectedMarker?.location.resolvedAddress as string)
                : "N/A"
            }
            accuracy={marker.accuracy || "N/A"}
            time={
              markerLoading
                ? marker.formattedDate
                : formatDate(selectedMarker?.location?.date)
            }
            handleObjectNameClick={() =>
              navigate({
                pathname: `/dashboard/objects/details/${marker.uniqueId}`,
              })
            }
          >
            {!isEmpty(selectedMarker) && !markerLoading ? (
              <ObjectMetadataTags object={selectedMarker} direction={"row"} />
            ) : (
              <SkeletonWrapper>
                <Skeleton variant="rectangular" />
              </SkeletonWrapper>
            )}
          </MapObjectCard>
        </InfoWindowF>
      )}
    </MarkerF>
  );
};

export default ObjectMarker;
