import { useState, useCallback } from "react";
import { useAppDispatch } from "./reduxHooks";
import { searchMinimalAccountsThunk } from "app/features";
import { debounce } from "lodash";

const useSearchDrawerAccounts = () => {
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState("");

  const debouncedSearch = useCallback(
    debounce((value) => {
      if (value) {
        dispatch(
          searchMinimalAccountsThunk({
            itemsPerPage: 100,
            page: 1,
            where: [{ field: "name", value, type: 0 }],
          })
        );
      }
    }, 800),
    []
  );

  const handleSearchChange = (event: any) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  const handleSearchClick = () => {
    debouncedSearch(searchValue);
  };

  const handleSearchKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };

  return {
    searchValue,
    handleSearchClick,
    handleSearchChange,
    handleSearchKeyPress,
    setSearchValue,
  };
};

export default useSearchDrawerAccounts;
