import { styled } from "@mui/material";
import { Field } from "formik";

export const Label = styled("label")({
  display: "flex",
  fontSize: "14px",
  flexDirection: "row",
  alignItems: "center",
  "&:hover": {
    cursor: "pointer",
  },
});

export const Checkbox = styled(Field)({
  width: "16px",
  height: "16px",
  marginRight: "6px",
  "&:hover": {
    cursor: "pointer",
  },
});
