import { FC, Fragment, ReactElement } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { AccordionDetails, Box } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionSummary,
  AccordionDetailsBox,
  SummaryContentBox,
  RidesTextBox,
} from "./ChartsAccordion.styled";

interface ChartItem {
  date: string;
  rides: number;
  hourDurations: any[];
}

interface DataItem {
  serialNumber: string;
  name: string;
  dataByDate: ChartItem[];
}

interface Props {
  data: DataItem[];
  expandedFirstLevel: string[];
  expandedSecondLevel: string[];
  handleFirstLevelAccordionChange: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  handleSecondLevelAccordionChange: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  chartID: string;
  parentWidth: number;
  parentRef: React.RefObject<HTMLDivElement>;
  renderGraphComponent: (chartItem: ChartItem) => ReactElement;
}

const ChartsAccordion: FC<Props> = ({
  data,
  expandedFirstLevel,
  handleFirstLevelAccordionChange,
  expandedSecondLevel,
  handleSecondLevelAccordionChange,
  parentRef,
  parentWidth,
  chartID,
  renderGraphComponent,
}) => {
  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  return (
    <Fragment>
      {data.map((item, index) => (
        <Accordion
          disableGutters
          key={`${item.serialNumber}-${index}`}
          elevation={0}
          expanded={expandedFirstLevel.includes(`panel${index}`)}
          onChange={handleFirstLevelAccordionChange(`panel${index}`)}
        >
          <AccordionSummary
            expandIcon={
              expandedFirstLevel.includes(`panel${index}`) ? (
                <ExpandLessIcon />
              ) : (
                <ChevronRightIcon />
              )
            }
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <SummaryContentBox>
              <Box>{item.name}</Box>
            </SummaryContentBox>
          </AccordionSummary>
          <AccordionDetails>
            {item.dataByDate.map((chartItem, chartIndex) => (
              <Accordion
                disableGutters
                key={`${chartItem.date}-${chartIndex}`}
                elevation={0}
                expanded={expandedSecondLevel.includes(
                  `subpanel${index}-${chartIndex}`
                )}
                onChange={handleSecondLevelAccordionChange(
                  `subpanel${index}-${chartIndex}`
                )}
              >
                <AccordionSummary
                  expandIcon={
                    expandedSecondLevel.includes(
                      `subpanel${index}-${chartIndex}`
                    ) ? (
                      <ExpandLessIcon />
                    ) : (
                      <ChevronRightIcon />
                    )
                  }
                  aria-controls={`subpanel${index}-${chartIndex}-content`}
                  id={`subpanel${index}-${chartIndex}-header`}
                >
                  <SummaryContentBox>
                    <Box>
                      {moment(chartItem.date).format("ddd, MMMM D, YYYY")}
                    </Box>
                    <RidesTextBox>
                      {chartItem.rides}{" "}
                      {chartItem.rides > 1
                        ? t("reports.sessions")
                        : t("reports.session")}
                    </RidesTextBox>
                  </SummaryContentBox>
                </AccordionSummary>
                <AccordionDetails>
                  <AccordionDetailsBox ref={parentRef}>
                    <Box id={`${chartID}-${index}-${chartIndex}`}>
                      {renderGraphComponent(chartItem)}
                    </Box>
                  </AccordionDetailsBox>
                </AccordionDetails>
              </Accordion>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Fragment>
  );
};

export default ChartsAccordion;
