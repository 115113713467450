import { FC, useMemo, useCallback } from "react";
import { Table, toast, TableColumn, utils } from "@vilocnv/allsetra-core";
import {
  getFileName,
  downloadReportFile,
  EXPORTED_REPORT_HISTORY_INNER_TABLE_COLUMNS,
} from "app/data/constants";
import { selectDrawerSelectedAccountId } from "app/data/selectors";
import {
  deleteExportedReportThunk,
  downloadReportFileThunk,
  searchExportedReportThunk,
} from "app/features";
import { useAppDispatch, useAppSelector } from "hooks";

interface ExportedReportsExpendableRowCardProps {
  data: any;
}

const ExportedReportsExpendableRowCard: FC<
  ExportedReportsExpendableRowCardProps
> = ({ data }) => {
  const dispatch = useAppDispatch();
  const drawerSelectedAccountId =
    useAppSelector(selectDrawerSelectedAccountId) || "";

  const handleDownloadClick = useCallback(
    async (
      row: {
        uniqueId: string;
        reportDefinition: { reportType: 2 | 3 };
        created: string;
      },
      fileId: string,
      fileType: string
    ) => {
      const { uniqueId, reportDefinition } = row;
      const { reportType } = reportDefinition;

      toast.info("Starting Download");

      const { type, payload } = await dispatch(
        downloadReportFileThunk({
          accountId: drawerSelectedAccountId,
          id: uniqueId,
          fileId,
          fileType,
        })
      );

      const fileName = getFileName(fileType, reportType, row);

      if (type === "exportedReportsHistory/downloadReportFileThunk/fulfilled") {
        //@ts-ignore
        downloadReportFile(payload, fileName);
      } else {
        toast.error("Server Error");
      }
    },
    [dispatch, drawerSelectedAccountId]
  );

  const tableColumns: TableColumn<any>[] = useMemo(
    () => EXPORTED_REPORT_HISTORY_INNER_TABLE_COLUMNS(handleDownloadClick),
    []
  );

  const handleDeleteReport = useCallback(
    async (row: { uniqueId: string }) => {
      const { uniqueId } = row;

      const { type } = await dispatch(
        deleteExportedReportThunk({
          accountId: drawerSelectedAccountId,
          uniqueId,
        })
      );

      if (
        type === "exportedReportsHistory/deleteReportScheduleThunk/fulfilled"
      ) {
        toast.success("Entry Deleted");

        dispatch(
          searchExportedReportThunk({
            accountId: drawerSelectedAccountId,
            params: utils.getCommonParamsForApi(),
          })
        );
      } else {
        toast.error("Server Error");
      }
    },
    [dispatch, drawerSelectedAccountId]
  );

  return (
    <Table
      data={data.records}
      columns={tableColumns}
      noTableHead={true}
      pagination={false}
      cellActions={[
        {
          name: "Delete",
          onClick: handleDeleteReport,
        },
      ]}
    />
  );
};

export default ExportedReportsExpendableRowCard;
