import { RootState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";
import { transformAccountsForDrawer } from "app/data/helpers";

export const selectAccountsReducerLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.loading;

export const selectSearchAccountsReducerLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.searchAccountsLoading;

export const selectAllAccounts = (state: RootState) =>
  state.rootReducer.accountsReducer.allAccounts;

export const selectAllDrawerSearchedAccounts = (state: RootState) =>
  state.rootReducer.accountsReducer.allDrawerSearchedAccounts;

export const selectDrawerInitialAccount = (state: RootState) =>
  state.rootReducer.accountsReducer.drawerInitialAccount;

export const selectActiveAccountId = (state: RootState) =>
  state.rootReducer.accountsReducer.activeAccountId;

export const selectSpecificAccount = (state: RootState) =>
  state.rootReducer.accountsReducer.specificAccount;

export const selectAccountsSelected = (state: RootState) =>
  state.rootReducer.accountsReducer.selectedAccounts;

export const selectSpecificAccountUser = (state: RootState) =>
  state.rootReducer.accountsReducer.accountUser;

export const selectAllUsersBasicInfo = (state: RootState) =>
  state.rootReducer.accountsReducer.usersBasicInfo;

export const selectAccountsState = createSelector(
  selectAccountsReducerLoading,
  selectSearchAccountsReducerLoading,
  selectAllAccounts,
  selectActiveAccountId,
  selectSpecificAccount,
  selectAccountsSelected,
  selectSpecificAccountUser,
  selectAllUsersBasicInfo,
  selectAllDrawerSearchedAccounts,
  selectDrawerInitialAccount,
  (
    loading,
    searchAccountsLoading,
    allAccounts,
    activeAccountId,
    specificAccount,
    selectedAccounts,
    accountUser,
    usersBasicInfo,
    allDrawerSearchedAccounts,
    drawerInitialAccount
  ) => {
    return {
      loading,
      searchAccountsLoading,
      allAccounts,
      activeAccountId,
      specificAccount,
      selectedAccounts,
      accountUser,
      usersBasicInfo,
      allDrawerSearchedAccounts,
      drawerInitialAccount,
    };
  }
);

export const selectActiveAccountState = createSelector(
  selectAccountsReducerLoading,
  selectActiveAccountId,
  selectSpecificAccount,
  selectAccountsSelected,
  (loading, activeAccountId, specificAccount, selectedAccounts) => ({
    loading,
    activeAccountId,
    specificAccount,
    selectedAccounts,
  })
);

export const selectAccountsForDrawer = createSelector(
  selectAccountsState,
  ({
    allAccounts,
    loading,
    allDrawerSearchedAccounts,
    searchAccountsLoading,
    drawerInitialAccount,
  }) => {
    return {
      loading,
      allAccounts,
      searchAccountsLoading,
      transformedAccounts: transformAccountsForDrawer(
        allDrawerSearchedAccounts
      ),
      drawerInitialAccount,
    };
  }
);

export const selectAccountsIndustriesLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.accountsIndustriesLoading;

export const selectAccountsIndustries = (state: RootState) =>
  state.rootReducer.accountsReducer.accountsIndustries;

export const selectAccountsTypesLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.accountsTypesLoading;

export const selectAccountsTypes = (state: RootState) =>
  state.rootReducer.accountsReducer.accountsTypes;

export const selectAccountMetadataState = createSelector(
  selectAccountsIndustriesLoading,
  selectAccountsIndustries,
  selectAccountsTypesLoading,
  selectAccountsTypes,
  (
    accountsIndustriesLoading,
    accountsIndustries,
    accountsTypesLoading,
    accountsTypes
  ) => ({
    accountsIndustriesLoading,
    accountsIndustries,
    accountsTypesLoading,
    accountsTypes,
  })
);

export const selectAccountGroups = createSelector(
  selectAccountsReducerLoading,
  (state: RootState) => state.rootReducer.accountsReducer.accountGroups,
  (loading, accountGroups) => ({
    loading,
    accountGroups,
  })
);

export const selectAccountObjects = createSelector(
  selectAccountsReducerLoading,
  (state: RootState) => state.rootReducer.accountsReducer.accountObjects,
  (loading, accountObjects) => ({
    loading,
    accountObjects,
  })
);

export const selectAccountPointsOfInterestsLoading = (state: RootState) =>
  state.rootReducer.accountsReducer.accountPointsOfInterestsLoading;

export const selectAccountPointsOfInterests = (state: RootState) =>
  state.rootReducer.accountsReducer.accountPointsOfInterests;

export const selectAccountPointOfInteretsState = createSelector(
  selectAccountPointsOfInterestsLoading,
  selectAccountPointsOfInterests,
  (accountPointsOfInterestsLoading, accountPointsOfInterests) => ({
    accountPointsOfInterestsLoading,
    accountPointsOfInterests,
  })
);

export const selectAccountFeaturesList = (state: RootState) =>
  state.rootReducer.accountsReducer.accountFeatures;

export const selectAccountFeaturesConfig = (state: RootState) =>
  state.rootReducer.accountsReducer.features;

export const selectAccountFeaturesState = createSelector(
  selectAccountFeaturesList,
  selectAccountFeaturesConfig,
  (accountFeatures, features) => ({ accountFeatures, features })
);
