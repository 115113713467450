import { FC, Fragment, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Stack, useTheme } from "@mui/material";
import { Topbar } from "@vilocnv/allsetra-core";
import { useTranslation } from "react-i18next";
import { HistoryClock, ScheduleReportGreyIcon } from "assets/icons";
import { TopbarLinkText } from "./ReportsTopbar.styled";
import { useAppSelector } from "hooks";
import { selectAccountFeaturesState } from "app/data/selectors";
import { compact } from "lodash";

interface Props {
  dropdownTitle: string;
  disabled?: boolean;
  hideScheduleReports?: boolean;
  hideReportHistory?: boolean;
}

const ReportsTopbar: FC<Props> = ({
  dropdownTitle,
  disabled = false,
  hideScheduleReports = false,
  hideReportHistory = false,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { features } = useAppSelector(selectAccountFeaturesState);

  const reportsDropdown = useMemo(
    () =>
      compact([
        {
          name: t("reports.tagPosition"),
          onClick: () => navigate("/dashboard/reports/tag-position"),
        },
        features.isRidesEnabled
          ? {
              name: t("reports.mileageRegistration"),
              onClick: () =>
                navigate("/dashboard/reports/mileage-registration"),
            }
          : null,
        {
          name: t("reports.workingHours"),
          onClick: () => navigate("/dashboard/reports/working-hours"),
        },
        features.isRidesEnabled
          ? {
              name: t("reports.graphicalRides"),
              onClick: () => navigate("/dashboard/reports/graphical-rides"),
            }
          : null,
        {
          name: t("reports.graphicalWorkingHours"),
          onClick: () => navigate("/dashboard/reports/graphical-working-hours"),
        },
        {
          name: t("reports.co2Report"),
          onClick: () => navigate("/dashboard/reports/co2-reports"),
        },
        {
          name: t("reports.multipleRides"),
          onClick: () => navigate("/dashboard/reports/multiple-rides"),
        },
      ]),
    [t, features]
  );

  return (
    <Topbar
      theme={theme}
      dropdownTitle={dropdownTitle}
      dropdownItems={reportsDropdown}
      disabled={disabled}
      increasedDropdownWidth={true}
    >
      {features.isAutomaticReportsEnabled && (
        <Fragment>
          <Stack gap={4} direction="row">
            {!hideScheduleReports && (
              <TopbarLinkText>
                <ScheduleReportGreyIcon />
                <Link
                  id="link-schedule-reports"
                  to="/dashboard/reports/schedule-reports"
                >
                  {t("reports.scheduledReports")}
                </Link>
              </TopbarLinkText>
            )}
            {!hideReportHistory && (
              <TopbarLinkText>
                <HistoryClock />
                <Link
                  id="link-reports-history"
                  to="/dashboard/reports/exported-history"
                >
                  {t("reports.reportsHistory")}
                </Link>
              </TopbarLinkText>
            )}
          </Stack>
        </Fragment>
      )}
    </Topbar>
  );
};

export default ReportsTopbar;
