import { styled } from "@mui/material";
import { Field } from "formik";

export const Label = styled("label")({
  display: "flex",
  fontSize: "16px",
  margin: "5px 0px",
  flexDirection: "row",
  alignItems: "center",
});

export const Checkbox = styled(Field)({
  width: "16px",
  height: "16px",
  marginRight: "6px",
});
